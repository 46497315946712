import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Kumar Abhijeet - software/infra tinkerer by day, `}<a parentName="p" {...{
        "href": "https://linktr.ee/ahemcholic"
      }}>{`noisemaker`}</a>{` by night.`}</p>
    <p>{`This is my space on the internet!`}</p>
    <p>{`I write about `}<a parentName="p" {...{
        "href": "/tags/software"
      }}>{`software`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/music"
      }}>{`music`}</a>{`, `}<a parentName="p" {...{
        "href": "/tags/mental-health"
      }}>{`mental-health`}</a>{` and `}<a parentName="p" {...{
        "href": "/tags/book-notes"
      }}>{`books`}</a>{` I've read.`}</p>
    <p>{`For opportunities and collaborations, reach me on `}<a parentName="p" {...{
        "href": "mailto:kumarabhijeet1202@gmail.com"
      }}>{`email`}</a>{` or my social channels mentioned above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      